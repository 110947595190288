@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #030712;
  --toastify-color-dark:  rgb(17 24 39);
  --toastify-toast-width: fit-content;
}

.Toastify__toast {
  border-radius: 0 !important;
  border-width: 1px;
  border-color: rgb(55 65 81);
  text-align: center;
}
